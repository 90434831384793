import { Stack } from "@mui/material";
import { debounce, find, get } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Dialog } from "../dialogs/Dialog";
import { MultiAttributeSelector } from "./MultiAttributeSelector";

export const SettingCompetenciesSelector = (props: any) => {
  const {
    onClose,
    onShowNewCategoriesialog,
    onSave,
    theme,
    initialSelectedCategories = [],
    categories,
    onSearch,
    isLoading,
    open
  } = props;

  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);

  const debouncedSearchCategories = useCallback(
    debounce((searchText: any) => onSearch(searchText), 500),
    []
  );

  const handleSearchCategories = (searchText: any) => {
    if (!searchText || searchText.trim() === "") {
      onSearch("");
    } else {
      debouncedSearchCategories(searchText);
    }
  };

  const handleAttributesChange = (e: any, selected: any) => {
    setSelectedCategories(selected);
  };

  const handleSave = () => {
    if (onSave) onSave(selectedCategories);
  };

  const categoriesFiltered = useMemo(() => {
    return categories;
  }, [categories]);

  useEffect(() => {
    setSelectedCategories(initialSelectedCategories);
  }, [initialSelectedCategories]);

  return (
    <Dialog
      open={open}
      theme={theme}
      onClose={onClose}
      fullWidth={true}
      maxWidth='sm'
      title='Filters'
      actions={[
        {
          label: "Apply",
          primary: true,
          disabled: selectedCategories.length === 0,
          onClick: handleSave
        },
        {
          label: "Cancel",
          onClick: onClose
        }
      ]}
    >
      <Stack direction='column' spacing={2}>
        <div>Categories</div>

        <MultiAttributeSelector
          label={null}
          items={categoriesFiltered}
          values={selectedCategories}
          onNewItemClick={onShowNewCategoriesialog}
          getItemId={(attr: any) => attr.categoryId}
          onChange={handleAttributesChange}
          onSearch={handleSearchCategories}
          hasNewItem={false}
          isLoading={isLoading}
        />
      </Stack>
    </Dialog>
  );
};
