import { AppContext } from "../../common/AppContext";
import { AbstractFilterSelect } from "./AbstractFilterSelect";
export class DomainSelector extends AbstractFilterSelect {
  static contextType = AppContext;

  static defaultProps = {
    ...AbstractFilterSelect.defaultProps,
    label: "Domain (optional):",
    matchFrom: "any"
  };

  constructor(props: any) {
    super(props);
  }

  getItemId(item: any) {
    return item.jobDefinitionDomainId;
  }

  getItemLabel(item: any) {
    const { getItemLabel } = this.props;

    if (getItemLabel) return getItemLabel(item);
    return item;
  }

  getList(dataList: any) {
    return dataList.domain;
  }
}
