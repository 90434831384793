import { Data } from "./Data";

export class Organization extends Data {
  static instance: any = null;

  //OrganizationStatus { Trial, Active, Inactive, Archived }
  static status: any = {
    trial: {
      label: "Trial"
    },
    active: {
      label: "Active"
    },
    inactive: {
      label: "Inactive"
    },
    archived: {
      label: "Archived"
    },
    resetting: {
      label: "Resetting"
    }
  };

  organizationId!: string;
  name!: string;
  showProficiency!: boolean;
  trialEndDate!: any;
  isDemo!: boolean;
  status!: any;
  demoOrgStatus!: any;
  isTrial!: boolean;
  hasIntegration!: boolean;
  hasActiveIntegration!: boolean;
  hasMultipleIntegrations!: boolean;
  address!: string;
  hasRegulationTasks!: boolean;
  trialMillisecondsRemaining!: number;
  trialDaysRemaining!: number;
  trialExpired!: boolean;
  hasRegulations!: boolean;
  enableJobMatching!: boolean;

  constructor(options: any) {
    super({
      api: "/Organization",
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data: any) {
    this.organizationId = data.organization.organizationId;
    this.name = data.organization.name;
    this.showProficiency = data.organization.showProficiency;
    this.trialEndDate = data.organization.trialEndDate
      ? new Date(data.organization.trialEndDate)
      : null;
    this.isDemo = data.organization.isDemo;
    this.status = Object.values(Organization.status)[data.organization.status];
    this.demoOrgStatus = Object.values(Organization.status)[data.organization.demoOrgStatus];
    this.isTrial = this.status === Organization.status.trial;
    this.hasIntegration = data.organization.hasIntegration;
    this.hasActiveIntegration = data.organization.hasActiveIntegration;
    this.hasMultipleIntegrations = data.organization.hasMultipleIntegrations;
    this.address = data.organization.address;
    this.hasRegulationTasks = data.organization.hasRegulationTasks;
    this.enableJobMatching = data.organization.enableJobMatching;
    this.hasRegulations = data.organization.hasRegulations;

    if (this.isTrial) {
      const today: any = new Date();
      this.trialMillisecondsRemaining = this.trialEndDate - today;
      this.trialDaysRemaining = Math.floor(this.trialMillisecondsRemaining / (1000 * 60 * 60 * 24));
      this.trialExpired = this.trialMillisecondsRemaining < 0;
    }
  }

  static get(context: any) {
    return new Promise((resolve, reject) => {
      if (Organization.instance) (resolve as any)(Organization.instance, true);
      else Organization.instance = new Organization({ resolve, reject, context });
    });
  }
}
